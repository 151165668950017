import * as React from 'react';
import Button from 'react-bootstrap/Button';
//import Modal from '@mui/material/Modal';
import Modal from 'react-bootstrap/Modal';

import LoginPage from './LoginPage.js';

const LoginWrapper = () => {

    const [showModal, setShowModal] = React.useState(false);
    const closeThis = () => setShowModal(false);
    
    return (
        <>
        <style type="text/css">
        {`
        .btn-primary {
            position: relative;
            top: 30%;
            font-family: ka1;
            background-color: rgba(0,0,0,0);
            margin-right: 5px;
            border: none;
        }

        .modal-body {
            padding: 0;
            margin: 0;
            border: none;
        }

        .modal-dialog modal-dialog-center {
            padding: 0;
            border: none;
            background-clip: content-box;
        }

        .modal-content {
            padding: 0;
            border: none;
            background-color: rgba(0,0,0,0)
        }
        
        `}
        </style>
        <Button onClick={() => setShowModal(true)}>Login</Button>
        <Modal centered='true' show={showModal} onHide={closeThis}>
            <Modal.Body>
            <LoginPage />
            </Modal.Body>
        </Modal>
        </>
    )
}


export default LoginWrapper;